import './App.css';

function App() {
  return (
    <>
      <div>HELLO</div>
      <div style={{ marginBottom: '20px' }}>❤️❤️</div>
      <div>
        <a href='https://beian.miit.gov.cn/' style={{ color: 'blue' }}>
          湘ICP备2020019461号-1
        </a>
      </div>
    </>
  );
}

export default App;
